import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import CaseStudyHeader from "../../../components/pages/work/case-studies/CaseStudyHeader"
import CaseStudyConsultation from "../../../components/pages/work/case-studies/CaseStudyConsultation"
import CaseStudyUserStories from "../../../components/pages/work/case-studies/CaseStudyUserStories"
import CaseStudyWireframes from "../../../components/pages/work/case-studies/CaseStudyWireframes"
import CaseStudyIterations from "../../../components/pages/work/case-studies/CaseStudyIterations"
import CaseStudyTechnologies from "../../../components/pages/work/case-studies/CaseStudyTechnologies"
import CaseStudyResults from "../../../components/pages/work/case-studies/CaseStudyResults"
import Contact from "../../../components/pages/index/Contact"
import CaseStudyChallenges from "../../../components/pages/work/case-studies/CaseStudyChallenges"

const DevLinkPage = ({ pageContext, location }) => {
  const consultationDetails = {
    company: "Developer Link",
    location: "Houston, TX",
    industry: "Web Development",
    developmentType: "Web Application",
    scope: "Design and Development",
    businessObjectives:
      "To provide a free social network for developers that will enhance Tactic Apps' reputation as well as create partnerships within the dev community.",
    desiredOutcomes:
      "To provide an easy to use platform for developers with profiles and post feeds. Ease of use should allow the web app to grow by 10 users a month.",
    targetMarket: "Web & Mobile Developers",
    competition: "Reddit",
    competitiveAdvantage:
      "DevLink will have profiles that resemble a resume which is very enticing for job and professional relationship seekers.",
    timeFrame: "4 Months",
  }

  const userStories = {
    mustHaves: [
      "Simple landing page with options to sign up, log in and see developer profiles",
      "A developer page to show list of all developers",
      "A profile view to see bio, location, skills, experience and education.",
      "Gravatar integration for profile images",
      "Github integration to show a user's latest repos",
      "Signup page with name, email and password",
      "Login page with email and password",
      "Every user must have a dashboard with links to edit their profile, education and experience",
      "Edit Profile Page",
      "Add Education Page",
      "Add Experience Page",
      "Post Feed for logged in users",
      "Each post to have its own comments",
      "Default Bootstrap colors for fast design",
    ],
    niceToHaves: [
      "Profile image uploads",
      "Developer profile page user filter",
      "Ability to DM through the user's profile",
      "Signup via Google and Facebook for faster profile creation",
      "Dashboard to have a messages section to reply to DMs",
      "Quick view of Education and Experience in the Dashboard",
      "Ability to delete and modify education and experience from the dashboard",
      "Like and dislike buttons on Posts",
      "Pagination on posts",
      "Latest posts displayed on user profile",
    ],
    maybeLater: [
      "Login via Facebook or Google credentials",
      "Block users with inappropriate DMs",
      "Post search button",
      "Show link previews in posts and comments",
      "Post Feed categories",
      "Inappropriate content filter",
      "Admin panel to manage users",
      "Advertising capabilities",
      "Show online presence with color dot on profile images",
      "Chat bot integration to help with common profile issues",
    ],
  }

  const iterations = {
    iteration1: {
      stories: [
        "Design and development environment setup",
        "Asset collection and review",
        "Wireframe review",
      ],
      weeks: "Week 1",
    },
    iteration2: {
      stories: [
        "Landing page design and development",
        "Footer design and development",
        "Nav bar design and development",
      ],
      weeks: "Week 2",
    },
    iteration3: {
      stories: [
        "Signup page design and development",
        "Login page design and development",
        "Database development for authentication",
        "API endpoint development",
      ],
      weeks: "Week 3-4",
    },
    iteration4: {
      stories: [
        "Edit Profile page design and development",
        "Database development for user profiles",
        "API endpoint development",
        "Gravatar Integration",
      ],
      weeks: "Week 4-5",
    },
    iteration5: {
      stories: [
        "Add Education page design and development",
        "Database development for education in user profile",
        "API endpoint development",
      ],
      weeks: "Week 6",
    },
    iteration6: {
      stories: [
        "Add Experience page design and development",
        "Database development for experience in user profile",
        "API endpoint development",
      ],
      weeks: "Week 7",
    },
    iteration7: {
      stories: [
        "Dashboard design and development",
        "Added ability to delete education and experience via Dashboard",
      ],
      weeks: "Week 8",
    },
    iteration8: {
      stories: ["Profile design and development", "API endpoint development"],
      weeks: "Week 9",
    },
    iteration9: {
      stories: [
        "Developer page design and development",
        "API endpoint development",
        "Forget Password page design and development",
      ],
      weeks: "Week 10-11",
    },
    iteration10: {
      stories: [
        "Post Feed design and development",
        "Like and dislike buttons added",
        "Database development for posts",
        "API endpoint development",
      ],
      weeks: "Week 12-13",
    },
    iteration11: {
      stories: [
        "Post Feed Comments design and development",
        "Database development for post comments",
        "API endpoint development",
      ],
      weeks: "Week 13-14",
    },
    iteration12: {
      stories: [
        "Release",
        "Emergency support standby as website is tested with live traffic",
      ],
      weeks: "Week 15",
    },
  }

  const technologies = {
    fullStack: true,
    icons: [
      "HTML5",
      "CSS3",
      "Sass",
      "Bootstrap",
      "JavaScript",
      "React",
      "Node",
      "MongoDB",
      "Jest",
      "Git",
      "Heroku",
    ],
  }

  const challenges = {
    challenge1: {
      challenge:
        "Tactic Apps had multiple client projects and therefore limited resources to dedicate to a big internal project.",
      win: (
        <>
          <p>
            Tactic Apps wanted to give back to the community while also keeping
            the lights on with multiple client projects. This made it
            challenging to assign team members to an internal project.
          </p>
          <p>
            Tactic Apps has developed a method to scale our team which allows
            new workers to onboard very quickly. This allows us to take on new
            projects with ease and with the same level of quality as what our
            clients have come to expect.
          </p>
          <p className="mb-0">
            Using this scaling method, we onboarded a new team member into an
            existing project and assigned the DevLink project to one of our
            strongest developers.
          </p>
        </>
      ),
    },
    challenge2: {
      challenge:
        "Tactic Apps had a short time frame for this internal project with a large list of stories.",
      win: (
        <>
          <p>
            As with most projects, our list of stories exceeded our available
            development time frame. Using our custom Agile Methodology, we
            prioritized all the stories, sized them up, and iterated through
            them to meet the deadline.
          </p>
          <p className="mb-0">
            After iterating through the stories for our 4 month time frame, we
            received clarity as to what we could build. We realized that this
            project would resemble an MVP and decided to execute the plan.
          </p>
        </>
      ),
    },
    challenge3: {
      challenge:
        "Implementing an intricate post feed system to include emojis, gifs and video.",
      win: (
        <>
          <p>
            Ideally, DevLink would have included an intricate post feed system
            to resemble a forum post where categories, emojis, gifs and video
            could be used. Unfortunately we did not allocate enough time or
            budget to make this happen.
          </p>
          <p>
            Adjusting the acceptance criteria for an iteration is common in web
            and mobile development so we decided to practice what we preach to
            our clients.
          </p>
          <p className="mb-0">
            Based on our time, budget, and resource availability we decided to
            use an MVP approach to the post feed system by building the most
            important functionality for communication and saving the more
            intricate details for the next release.
          </p>
        </>
      ),
    },
    challenge4: {
      challenge:
        "Midway through the development process we realized we forgot to add a password reset option.",
      win: (
        <>
          <p>
            It's common for projects to change in size or scope as they begin to
            take shape. We realized midway through that we forgot to add a
            password reset option which in itself is not a small task to get
            right. It requires new API endpoints, email integration and security
            tokens.
          </p>
          <p className="mb-0">
            We knew we could not budge on time line and budget and had to make
            adjustments to the scope. Fortunately the DevLink project was on
            track to finish early and we were able to add the password reset
            story to one of our iterations.
          </p>
        </>
      ),
    },
    challenge5: {
      challenge:
        "Github changed their API authentication method which unknowingly broke user profiles.",
      win: (
        <>
          <p>
            Whenever we or our clients use external APIs, we all run the risk of
            the service changing or shutting down. Github made authentication
            changes after we developed and tested the user profile page which
            broke the repository display section.
          </p>
          <p className="mb-0">
            We didn't notice this issue until after the release day.
            Fortunately, Tactic Apps has an emergency service for all releases
            which we used to patch the problem within a matter of hours.
          </p>
        </>
      ),
    },
  }

  const caseStudyData = {
    numBoxes: {
      developers: "1",
      hourly: "85",
      time: "4",
    },
  }

  const resultsImageNames = {
    results1: "Landing Page",
    results2: "Developers",
    results3: "Profile",
    results4: "Signup",
    results5: "Login",
    results6: "Dashboard",
    results7: "Edit Profile",
    results8: "Add Experience",
    results9: "Add Education",
    results10: "Post Feed",
    results11: "Comments",
  }

  return (
    <StaticQuery
      query={graphql`
        {
          hero: file(
            relativePath: { eq: "work/web/devlink/devlink-hero.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          deviceDisplay: file(
            relativePath: { eq: "work/web/devlink/devlink-devices.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe1: file(
            relativePath: {
              eq: "work/web/devlink/wireframes/devlink_landing_page.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe2: file(
            relativePath: {
              eq: "work/web/devlink/wireframes/devlink_developers.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe3: file(
            relativePath: {
              eq: "work/web/devlink/wireframes/devlink_profile.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe4: file(
            relativePath: {
              eq: "work/web/devlink/wireframes/devlink_signup.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe5: file(
            relativePath: {
              eq: "work/web/devlink/wireframes/devlink_login.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe6: file(
            relativePath: {
              eq: "work/web/devlink/wireframes/devlink_dashboard.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe7: file(
            relativePath: {
              eq: "work/web/devlink/wireframes/devlink_edit_profile.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe8: file(
            relativePath: {
              eq: "work/web/devlink/wireframes/devlink_add_experience.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe9: file(
            relativePath: {
              eq: "work/web/devlink/wireframes/devlink_add_education.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe10: file(
            relativePath: {
              eq: "work/web/devlink/wireframes/devlink_post_feed.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe11: file(
            relativePath: {
              eq: "work/web/devlink/wireframes/devlink_comments.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          results1: file(
            relativePath: { eq: "work/web/devlink/1-devlink-landing.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results2: file(
            relativePath: { eq: "work/web/devlink/2-devlink-developers.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results3: file(
            relativePath: { eq: "work/web/devlink/2-1-devlink-profile.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results4: file(
            relativePath: { eq: "work/web/devlink/3-devlink-signup.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results5: file(
            relativePath: { eq: "work/web/devlink/4-devlink-login.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results6: file(
            relativePath: { eq: "work/web/devlink/5-devlink-dashboard.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results7: file(
            relativePath: { eq: "work/web/devlink/6-devlink-edit-profile.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results8: file(
            relativePath: {
              eq: "work/web/devlink/7-devlink-add-experience.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results9: file(
            relativePath: { eq: "work/web/devlink/8-devlink-add-education.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results10: file(
            relativePath: { eq: "work/web/devlink/9-devlink-post-feed.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results11: file(
            relativePath: { eq: "work/web/devlink/10-devlink-comments.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <Layout>
          <Seo
            title="DevLink Case Study"
            keywords={[`devlink web app case study`]}
            description="Developer Link is a free social media platform for web developers. It is a fullstack web application built with React, NodeJS and MongoDB."
          />
          <CaseStudyHeader
            image={data.hero.childImageSharp.gatsbyImageData}
            title="Web Application Case study"
            subTitle="Developer Link"
            pageContext={pageContext}
            location={location}
            crumbLabel="Developer Link"
          />
          <main>
            <CaseStudyConsultation
              consultationDetails={consultationDetails}
              deviceImage={data.deviceDisplay}
            />
            <CaseStudyUserStories
              userStories={userStories}
              companyName={consultationDetails.company}
            />
            <CaseStudyWireframes
              wireframeImages={data}
              companyName={consultationDetails.company}
            />
            <CaseStudyIterations
              iterationData={iterations}
              companyName={consultationDetails.company}
            />
            <CaseStudyTechnologies
              technologies={technologies}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <CaseStudyChallenges
              challenges={challenges}
              companyName={consultationDetails.company}
            />
            <CaseStudyResults
              caseStudyData={caseStudyData}
              resultsImages={data}
              resultsImageNames={resultsImageNames}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <Contact
              heading={
                <>
                  Let's build your{" "}
                  <span className="text-primary">
                    {consultationDetails.developmentType}
                  </span>{" "}
                  Together
                </>
              }
            />
          </main>
        </Layout>
      )}
    />
  )
}

export default DevLinkPage
